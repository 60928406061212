import React from "react";
import PropTypes from "prop-types";
import { graphql, Link, StaticQuery } from "gatsby";

class CareersRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: jobs } = data.allMarkdownRemark;

    const liveJobs = jobs.filter(
      ({ node: job }) => job.frontmatter.live === true
    );

    return (
      <div>
        <div className="content">
          Thank you for checking out our careers page. We are very excited and
          motivated by the chance we have to change the face of
          neurorehabilitation and want to hire like-minded individuals. Are you
          interested?
        </div>
        {liveJobs.length > 0 ? (
          <>
            <div className="content">
              Please browse our currently advertised jobs below.
            </div>
            {liveJobs.map(({ node: job }) => (
              <div
                className="is-parent column is-12"
                key={job.frontmatter.title}
              >
                <article
                  className={`blog-list-item tile is-child box notification`}
                >
                  <header>
                    <p className="post-meta">
                      <Link
                        className="title has-text-primary is-size-4"
                        to={job.fields.slug}
                      >
                        {job.frontmatter.title}
                      </Link>
                      <span> &bull; </span>
                      <span className="subtitle is-size-5 is-block">
                        {job.frontmatter.date}
                      </span>
                    </p>
                  </header>
                  <p>
                    {job.excerpt}
                    <br />
                    <br />
                    <Link className="button" to={job.fields.slug}>
                      Keep Reading →
                    </Link>
                  </p>
                </article>
              </div>
            ))}
            <div className="content pt-6">
              Or, if none of these match you, you can...
            </div>
            <div className="level">
              <div className="level-item">
                <Link
                  className="button is-rounded is-medium has-text-info"
                  to="/contact?type=job-application"
                >
                  Make a speculative application
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="content">
              Unfortunately there are no currently advertised job posts. But
              don't let that stop you...
            </div>
            <div className="level">
              <div className="level-item">
                <Link
                  className="button is-rounded is-medium has-text-info"
                  to="/contact?type=job-application"
                >
                  Make a speculative application
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

CareersRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function CareersRoll() {
  return (
    <StaticQuery
      query={graphql`
        query CareersRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "careers-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  live
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <CareersRollTemplate data={data} count={count} />
      )}
    />
  );
}
