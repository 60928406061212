import * as React from "react";

import Layout from "../../components/Layout";
import CareersRoll from "../../components/CareersRoll";
import { TextBanner } from "../../components/molecules/TextBanner";
import { LayoutBox } from "../../components/atoms/LayoutBox";

export default class CareersIndexPage extends React.Component {
  render() {
    return (
      <Layout
        title="Careers"
        description="Current neumind career opportunities."
      >
        <TextBanner title="Careers at neumind" />
        <LayoutBox>
          <CareersRoll />
        </LayoutBox>
      </Layout>
    );
  }
}
